import React, {Component} from "react"
import {connect} from "react-redux"
import Select from 'react-select'
import {getFriendsForUniverseCreate as getFriends, createNewUniverse,resetCreateUniverse} from "../../store/actions/UniverseActions"

class UniverseCreate extends Component {
	constructor(){
		super()

		this.state = {
			selectedFriends : [],
		}

	}
	componentDidMount(){
		this.props.getFriends()
	}
	componentDidUpdate(prevProps){
		if(this.props.universeCreateSuccess !== prevProps.universeCreateSuccess){
			this.props.resetCreateUniverse()
			this.setState({
				title: "",
				description: "",
				friends: [],
			})
		}
	}
	handleChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	};
	handleUserSelect = ({value}) => {
		const newSelectedFriends = this.state.selectedFriends.concat(value)

		this.setState({
			selectedFriends: newSelectedFriends
		})
	};
	removeFriend = (userId) => {
		const filteredSelectedFriends = this.state.selectedFriends.filter(friend=>!friend.id===userId)

		this.setState({
			selectedFriends: filteredSelectedFriends
		})
	};
	handleCreateUniverse = () => {
		const {title,description,selectedFriends} = this.state
		this.props.createNewUniverse({
			title,
			description,
			friends: selectedFriends
		})
	}
	render(){
		return(	
			<div className="Create-Universe-Form">
				<div className="field">
					<label className="label">Title</label>
					<div className="control">
						<input onChange={this.handleChange} className="input" name="title"/>
					</div>
				</div>
				<div className="field">
					<label className="label">Description</label>
					<div className="control">
						<input onChange={this.handleChange} className="input" name="description" />
					</div>
				</div>
				<div className="Universe-Create-Selected-Friends">
					{this.props.friends
						.filter(friend=> this.state.selectedFriends.includes(friend.id))
						.map(friend=>(
						<div>
							{friend.username} <span onClick={()=>this.removeFriend(friend.id)}>X</span>
						</div>
					))}
				</div>
				<label className="label">Friends</label>
				<Select 
					options={this.props.friends
							.filter(friend=> !this.state.selectedFriends.includes(friend.id))
							.map(friend=>(
						{
							label:friend.username,
							value:friend.id
						})
					)}
					value={null}
					onChange={this.handleUserSelect}
				/>
				<br/>
				<button onClick={this.handleCreateUniverse} className="button">Submit</button>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		friends: state.Universe.friends,
		universeCreateSuccess: state.Universe.universeCreateSuccess,
	}
}

export default connect(mapStateToProps,{getFriends,createNewUniverse,resetCreateUniverse})(UniverseCreate)