import React, { Component } from 'react';
import {connect} from "react-redux"
import moment from "moment"
import {Link} from "react-router-dom";
import {getPostDetails, getComments, addPostComment, sendMessageWithPost, editPost} from '../../store/actions/PostActions'
import "../../styles/Post.css"

const PostDetail = ({postDetails, requestPost, username, fufillRequest}) =>{
	const {post,tags} = postDetails
	return(
		!!post?
		<div>
			<div className="Post-Image-Container">
				<img className="Post-Image" src={post.image} alt="NONE"/>
			</div>
			<div className="Post-Title">
				<h1 className="bold">{post.title}</h1>
				{
					!post.isFufilled?
						post.user.username === username?
							<div>
								<button onClick={fufillRequest}> {post.isRequest? "Request Fufilled" : "Mark Item as Claimed"}</button>
							</div>
							:
							<div className="Post-Requst-Item">
								<button className="Request-This-Item" onClick={requestPost}>{post.isRequest? "Fufill Request":"Request this Item"}</button>
							</div>
						:
						<div className="Post-Requst-Item">
							<h1>{post.isRequest? "Request has been fufilled":"Item has been claimed"}</h1>
						</div>
				}
			</div>
			<div className="Post-Info-Container">
				<div className="Post-Description">
					{post.description}
				</div>
				
				<div className="Post-Time">
					{post.eventStart}-{post.eventEnd}
				</div>
				<div className="Post-User">
					<Link to={`/profile/${post.user.username}`} >{post.user.username}</Link>
				</div>
				<div className="Post-Category">
					{post.category}
				</div>
				<div className="Post-Tags">
					<ul>
						{tags.map(tag=><li>{tag}</li>)}
					</ul>
				</div>
			</div>
		</div>
		:
		<h1>Error getting post!</h1>
	)
}

const Comments = ({comment}) => {
	return(
		<div className="Comment-Container">
			<div className="Comment--image-container image is-64x64">
				<Link to={`/profile/${comment.user.username}`}><img className="Comment-Image" src={comment.user.profileImage} alt="None" /></Link>
			</div>
			<div className="Comment-Info-Container">
				<div className="Comment-Info-Meta">
					<Link to={`/profile/${comment.user.username}`}><h1 className="is-size-7">{comment.user.username}</h1></Link>
					<h1 className="is-size-7">{moment(comment.createdAt).fromNow()}</h1>
				</div>
				<h1>{comment.comment}</h1>
			</div>
		</div>
		)
}

const AddComment = (props) => {
	return(
		<div className="PostView-Add-Comment">
			<input className="input" name="newComment" placeholder="Ask something others may want to know..." onChange={props.handleChange} />
			<button className="Post-Add-Comment-Button" onClick={props.handleAddComment}>Add Comment</button>
		</div>
	)
}

class PostView extends Component {
	componentDidMount(){
		const postId = this.props.match.params.id

		if(postId){
			this.props.getPostDetails(postId)
			this.props.getComments(postId)
		}else{
			// Redirect to all posts or something
		}
	}

	handleAddComment = () => {
		const newComment = {
			comment: this.state.newComment
		}
		this.props.addPostComment(newComment,this.props.match.params.id)
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	};

	handleRequestPost = () => {
		const successRedirect = (chatId)=>this.props.history.push(`/inbox/messages/${chatId}`)
		this.props.sendMessageWithPost(this.props.match.params.id,successRedirect)
	};
	fufillRequest = () => {
		this.props.editPost({isFufilled:true},this.props.postDetails.post.id)
	}
	render(){
		console.log(this.props.postDetails)
		return(
			<div>
				<PostDetail postDetails={this.props.postDetails} requestPost={this.handleRequestPost} username={this.props.username} fufillRequest={this.fufillRequest}/>
				<div>
					{this.props.comments.map(comment=><Comments comment={comment}/>)}
				</div>
				<AddComment handleChange={this.handleChange} handleAddComment={this.handleAddComment}/>
			</div>
		)
	}
}

const mapStateToProps = (state) =>{
	return{
		postDetails: state.Post.postDetails,
		comments: state.Post.comments,
		username: state.Auth.username,

	}
}
export default connect(mapStateToProps, {getPostDetails, addPostComment, getComments, sendMessageWithPost, editPost})(PostView)