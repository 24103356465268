import React, { Component } from 'react';
// import {connect} from "react-redux"
import {Link,withRouter} from "react-router-dom"
import privacyPolicy from "../../documents/FreeSpot Co. - Privacy Policy.pdf"
import termsAgreement from "../../documents/FreeSpot Co. - Terms of Service.pdf"
import "../../styles/Footer.css"


class Footer extends Component {
	render(){
		console.log(this.props)
		return(
			<div className="navbar Navigation-Mobile is-hidden-mobile">
				<div className="webfooter">
					<div className="copyright"><h3>© 2019 FreeSpot Co. All Rights Reserved.</h3></div>
					<div className="">
						<Link to="/HomePage"><h3>About</h3></Link>
					</div>
					<div className="">
						<a href={privacyPolicy} target="_blank"><h3>Privacy</h3></a>
					</div>

					<div className="">
						<a href={termsAgreement} target="_blank"><h3>Terms</h3></a>
					</div>

					<div className="">
						<a href="mailto:team@freespot.co"><h3>Support</h3></a>
					</div>
					
					<div className="">
						<Link to="/"><h3>FAQ</h3>
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Footer)