import React, {Component} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {fetchUniverses,selectUniverse} from "../../store/actions/UniverseActions"
import Select from "react-select"
import UniverseView from "./UniverseView"

const UniverseListItem = ({universe}) => {
	return (
		<Link to={`/universe/${universe.id}`} >
			<div className="Universe-list-item">
				<h1>{universe.title}</h1>
			</div>
		</Link>
	)
}

class AllUniverses extends Component {
	componentDidMount(){
		this.props.fetchUniverses()
	}
	render(){
		console.log(this.props)
		return(	
			<div className="Universe-Container">
				<div className="Universe-Banner is-hidden-mobile">
					<div className="Universe-Dropdown">
						<Select
							options={this.props.universes.map(universe=> ({value:universe.id,label:universe.title}))} 
							onChange={this.props.selectUniverse}
						/>
					</div>
					<h1 className="Universe-Banner-Title">UNIVERSE</h1>
					
				</div>
				<div className="Universe-tools">
					<div className="is-hidden-tablet">
						<Select
							options={this.props.universes.map(universe=> ({value:universe.id,label:universe.title}))} 
							onChange={this.props.selectUniverse}
						/>
					</div>
					<h6 className="UniverseDescription">What's a Universe?</h6>
					<h6 className="UniverseDescriptiontext">After you have gone to your friend's profiles and sent them Friend Requests, when they accept you, you can now begin to add them to Universes. Universes can be used to share things with only a select group of people. Users outside of your universe will never know these posts exist. We want to take as much of the stigma away from sharing used items as we can so please also use these spaces to make requests that you know your group of friends may be able to fulfill. </h6>
					<Link to="/universe/create">
						<button className="Universe-Create-Universe-Button">Create A New Universe</button>
					</Link>
				</div>
				<UniverseView selectedUniverse={this.props.selectedUniverse}/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		universes: state.Universe.universes,
		selectedUniverse: state.Universe.selectedUniverse
	}
}

export default connect(mapStateToProps, {fetchUniverses,selectUniverse})(AllUniverses)