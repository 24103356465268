const initialState = {
	messenger:false,
	requests: []
}

export default function (state = initialState, action){
	switch(action.type){
		case 'FETCH_FRIEND_REQUESTS':
			return {
				...state,
				requests: action.payload
			};
		case 'CHANGE_INBOX_VIEW':
			return {
				...state,
				messenger: action.payload
			};
		default:
			return state;
	}
}