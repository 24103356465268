const initialState = {
	currentUser: {},
	friendRequestSuccess: false,
	friendRequestFail: false
}

export default function (state = initialState, action){
	switch(action.type){
		case 'FETCH_PROFILE':
			return {
				...initialState,
				currentUser: action.payload
			};
		case 'FRIEND_REQUEST_SENT':
			return {
				...state,
				friendRequestSuccess: true
			};
		case 'FRIEND_REQUEST_FAILED':
			return {
				...state,
				friendRequestFail: true
			};
		default:
			return state;
	}
}