import {fetchPostList, fetchUserLists, searchByTag} from "../../utils/api"

export const startSearch = (searchTerm) => (dispatch) => {
	dispatch({
		type: "SEARCH_SETUP_SEARCH_TERM",
		payload: searchTerm
	})
}

export const searchPosts = (search) => async (dispatch) => {
	try{
		dispatch({type:"SEARCH_POSTS_GET_RESULTS_START"})
		const {data} = await fetchPostList({search})
		console.log(data)
		if(data.success){
			dispatch({
				type: "SEARCH_POSTS_GET_RESULTS",
				payload: data.posts
			})
		}else{
			dispatch({
				type: "SEARCH_POSTS_GET_RESULTS_FAILED",
			})
		}
	}catch(error){
		dispatch({
			type: "SEARCH_POSTS_GET_RESULTS_FAILED",
		})
	}
}

export const searchUsers = (search) => async (dispatch) => {
	try{
		dispatch({type:"SEARCH_USERS_GET_RESULTS_START"})
		const {data} = await fetchUserLists({search})
		if(data.success){
			dispatch({
				type: "SEARCH_USERS_GET_RESULTS",
				payload: data.users
			})
		}else{
			dispatch({
				type: "SEARCH_USERS_GET_RESULTS_FAILED",
			})
		}
	}catch(error){
		dispatch({
			type: "SEARCH_USERS_GET_RESULTS_FAILED",
		})
	}
}

export const searchPostByTag = (search) => async (dispatch) => {
	try{
		dispatch({type:"SEARCH_USERS_GET_RESULTS_START"})
		const {data} = await searchByTag(search)
		if(data.success){
			dispatch({
				type: "SEARCH_POSTS_GET_RESULTS",
				payload: data.posts
			})
		}else{
			dispatch({
				type: "SEARCH_POSTS_GET_RESULTS_FAILED",
			})
		}
	}catch(error){
		dispatch({
			type: "SEARCH_POSTS_GET_RESULTS_FAILED",
		})
	}
}

export const changeSearchType = (searchType) => (dispatch) => {
	dispatch({
		type: "CHANGE_SEARCH_TYPE",
		payload: searchType
	})
}