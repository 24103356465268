import React, { Component } from 'react';
import {connect} from "react-redux"
import {fetchFriendRequests, acceptRequest} from "../../store/actions/InboxActions"
import "../../styles/Inbox.css"
import ProfilePlaceholder from "../../styles/freespot_userprofile_default.png"

const RequestBlurb = ({request,acceptRequest}) => {
	return (
		<div className="Inbox-FriendRequest-Container">
			<div className="Inbox-FriendRequest-Image-Container">
				<img src={request.profileImage || ProfilePlaceholder} />
			</div>
			<h1 className="Request-UserName">{request.username}</h1>
			<button className="FriendRequestAccept" onClick={()=>acceptRequest(request.id)}>Accept</button>
		</div>
	)
} 

class FriendRequests extends Component {
	componentDidMount(){
		this.props.fetchFriendRequests()
	}

	acceptRequest = (userId) => {
		this.props.acceptRequest(userId)
	}

	render(){
		return(
			<div>
				
				{this.props.requests.map(request=>
					<RequestBlurb request={request} acceptRequest={this.acceptRequest}/>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return{
		requests: state.Inbox.requests
	}
}

export default connect(mapStateToProps,{fetchFriendRequests, acceptRequest})(FriendRequests)