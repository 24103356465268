import React, { Component } from 'react';
import {connect} from "react-redux"
import {getPostForFeed, getMorePosts} from "../../store/actions/HomeActions"
import PostBlock from "../../ui-components/PostBlock"
import "./Feed.css"
import PostPlaceholder from "../../styles/freespot_userpost_default.png"
import ProfilePlaceholder from "../../styles/freespot_userprofile_default.png"

class Feed extends Component {
	componentDidMount(){
		const category = this.props.selectedCategory
		this.props.getPostForFeed({categoryId: category})
	}
	endOfFeed = () => {
		this.props.getMorePosts({
			categoryId: this.props.selectedCategory,
			previousPost: this.props.lastPost
		})
	}

	render(){
		return(
			<span>
			<h1 className="choose-category" > Choose a Category</h1>
				<div className="Feed-Banner is-hidden-mobile">
					<span className="Feed-Public-Title">PUBLIC</span>

				</div>
				<div className="Feed-Container">
					{this.props.postsList.map(post=><PostBlock post={post} />)}
					
				</div>
				<center>
					<div className="Feed-Get-More" >
						<button 
							className="Feed-Get-More-Button"
							onClick={this.endOfFeed}
						>
							Get More Posts
						</button>
					</div>
				</center>
			</span>
		)
	}
}

const mapStateToProps = (state) =>{
	return {
		postsList: state.Home.postsList,
		selectedCategory: state.Home.selectedCategory,
		lastPost: state.Home.lastPost,
	}
}

export default connect(mapStateToProps,{getPostForFeed, getMorePosts})(Feed)