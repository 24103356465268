import {createUniverse, getUniverses, getUniverseById, getFriends} from "../../utils/api"
import {notify} from "../../ui-components/Notification"

export const createNewUniverse = (newUniverse) => async (dispatch) =>{
	try{
		const {data} = await createUniverse(newUniverse)
		if(data.success){
			dispatch({
				type: "UNIVERSE_CREATE_SUCCESS"
			})
			dispatch(notify({title:"Success",message:"Universe Created"}))
		}else{
			dispatch(notify({title:"Error",message:"Failed to Create Universe", level:"error"}))
		}
	}catch(error){
		dispatch(notify({title:"Error",message:"Failed to Create Universe", level:"error"}))
	}
}
export const resetCreateUniverse = () => (dispatch) => {
	dispatch({
		type: "UNIVERSE_CREATE_CLEAR"
	})
}

export const getFriendsForUniverseCreate = () => async (dispatch) => {
	try{
		const {data} = await getFriends()
		if(data.success){
			dispatch({
				type: "UNIVERSE_FRIENDS_CHOICES",
				payload: data.friends
			})
		}
	}catch(error){
		dispatch(notify({title:"Error",message:"Failed to get friends", level:"error"}))
	}
}

export const fetchUniverses = () => async (dispatch) => {
	try{
		dispatch({type:"UNIVERSE_FETCHING"})
		const {data} = await getUniverses()
		if(data.success){
			dispatch({
				type:"UNIVERSE_FETCH_SUCCESS",
				payload: data.universes
			})
		}else{
			dispatch({type:"UNIVERSE_FETCH_FAILED"})
			dispatch(notify({title:"Error",message:"Failed to get Universes", level:"error"}))
		}
	}catch(error){
		dispatch({type:"UNIVERSE_FETCH_FAILED"})
		dispatch(notify({title:"Error",message:"Failed to get Universes", level:"error"}))
	}
}

export const selectUniverse = ({value}) => (dispatch) => {
	dispatch({
		type: "SELECT_UNIVERSE",
		payload: value
	})
}
export const changeUniverseDisplay = (view) => (dispatch) => {
	dispatch({
		type: "UNIVERSE_DISPLAY",
		payload: view,
	})
}
export const getUniverseDetail = (id) => async (dispatch) => {
	try{
		dispatch({type:"UNIVERSE_DETAIL_FETCH"})
		const {data} = await getUniverseById(id)
		if(data.success){
			dispatch({
				type:"UNIVERSE_DETAIL",
				universe: data.universe,
				users: data.users,
				posts: data.posts,
			})
		}else{
			dispatch({
				type: "UNIVERSE_DETAIL_FETCH_FAIL"
			})
		}
	}catch(error){
		dispatch({
			type: "UNIVERSE_DETAIL_FETCH_FAIL"
		})
	}
}