import React, { Component } from 'react';
import LoginForm from "../Header/LoginForm"
import "./Landing.css"
import donateImage from "../../styles/bg-cta.jpg"

class LandingPage extends Component {
	render(){
		return(
			<div>
		
				<div>
					<div className="Landing-Register" id="Register">
			
						<div className="is-hidden-mobile">
							<h3 className="is-size-4"></h3>
							<LoginForm />
						</div>
					</div>
					<div className="Landing-End"/>
				</div>
				{/*is-hidden-desktop meants this will only be visible on tablets and cellphones*/}
				<div className="is-hidden-tablet">
					<div className="mobile-login-form">
						<LoginForm />
					</div>
				</div>
			</div>
		)
	}
}

export default LandingPage