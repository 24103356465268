import React, { Component } from 'react';
import {connect} from "react-redux"
import {Link, withRouter} from 'react-router-dom';
import {loginModalToggle,registerModalToggle,logout} from '../../store/actions/HomeActions'
import {startSearch} from '../../store/actions/SearchActions'
import "../../styles/header.css"
import Logo from "../../styles/freespotfavicon_lg.png"

class Header extends Component {
	constructor(){
		super()
		this.state={
			searchOpen:false
		}
	}

	toggleSearch = () => {
		this.setState({
			searchOpen: !this.state.searchOpen
		})
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	};

	handleSearch = () => {
		const searchTerm = this.state.search
		this.props.startSearch(searchTerm)

		this.setState({
			search:"",
			searchOpen:false
		})
		this.props.history.push("/search")
	}

	render(){
		console.log(this.props)
		return(
			<span>
				<div className="Header-container is-hidden-mobile">
					<Link to="/"><div className="Header-item-container-left" style={{height:"100%"}}>
						FreeSpot
					</div></Link>
					{
					this.props.loggedIn?
					<div className="Header-item-container-right">
						<Link to="/profile"><p className="Header-item">Profile</p></Link>
						{this.state.searchOpen?
							<span className="Search-Container Header-item">
							<input name="search" onChange={this.handleChange} className="SearchInput input is-rounded" />
							<button className="headerbutton" onClick={this.handleSearch}>GO</button>
							<button className="headerbutton" onClick={this.toggleSearch}>X</button>
							</span>
							:
							<p onClick={this.toggleSearch} className="Header-item">Search</p>
						}
						<a href=""><p onClick={this.props.logout}>Logout</p></a>
					</div>
					:
					<div className="Header-item-container-right">
						<h1 className="Header-item" onClick={()=>this.props.loginModalToggle(true)}><Link to="/register">Login</Link></h1>
						<span className="Header-item"></span>
						<h1 className="Header-item" onClick={()=>this.props.loginModalToggle(false)}><Link to="/register">Register</Link></h1>
					</div>
					}

				</div>
				<Link to="/"><img className="Mobile-Header-Icon is-hidden-tablet" src={Logo} /></Link>
			</span>
		)
	}
}

const mapStateToProps = (state) => {
	return{
		loggedIn: state.Auth.loggedIn
	}
}

export default connect(mapStateToProps,{loginModalToggle,registerModalToggle,startSearch,logout})(withRouter(Header))