const initialState = {
	postDetails: false,
	isFetchingComments:false,
	comments: [],
	categoryList:[],
	createPostSuccess: false,
	createPostFail: false,
	message: ""
}

export default function (state = initialState, action){
	switch(action.type){
		case 'EXIT_POST_VIEW':
			return {
				...initialState
			};
		case 'FETCH_ONE_POST':
			return {
				...state,
				postDetails: action.post,
				comments: action.comments
			};
		case "FETCHING_COMMENTS":
			return{
				...state,
				isFetchingComments:true
			}
		case "FETCH_COMMENTS_SUCCESS":
			return {
				...state,
				isFetchingComments: false,
				comments: action.payload
			}
		case "FETCH_COMMENTS_FAILED":
			return {
				...state,
				isFetchingComments: false,
				error: "Failed to fetch comments"
			}
		case "FETCH_CATEGORY_LIST":
			return{
				...state,
				categoryList: action.payload
			}
		case "CREATE_POST_SUCCESS":
			return {
				...state,
				createPostSuccess: true,
				createPostFail: false,
			}
		case "CREATE_POST_FAILED":
			return {
				...state,
				createPostFail: true,
				message: action.payload
			}
		case "RESET_CREATE_POST_FORM":
			return {
				...state,
				createPostSuccess: false
			}
		case "CREATE_POST__FETCH_UNIVERSE_LIST":
			return{
				...state,
				universeList: action.payload
			}
		default:
			return state;
	}
}