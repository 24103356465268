import axios from 'axios';
import FileDownload from 'js-file-download'
// const baseURL = "http://localhost:8080/"
const baseURL = "https://freespot-server.herokuapp.com/"
var header = function(){
	return {headers: {'Authorization': "bearer " + localStorage.getItem("token")}}
}

// ******** AUTH/USER API CALLS ************

export const loginApi = (data) =>{
	let url = baseURL + "api/login"
	return axios.post(url,data)
}

export const getYourProfile = () =>{
	let url = baseURL + "api/user"
	return axios.get(url,header())
}

export const registerApi = (data) =>{
	let url = baseURL + "api/user"
	return axios.post(url,data)
}

export const updateProfile = (data) =>{
	let url = baseURL + "api/user"
	return axios.put(url,data, header())
}

export const getUserProfile = (username) =>{
	let url = baseURL + `api/user/${username}`
	return axios.get(url,header())
}

export const fetchUserLists = (params={}) => {
	let url = baseURL + "api/searchUsers"
	return axios.get(url,{params})
}

export const forgotPasswordApi = (email) => {
	let url = baseURL + "api/forgotpassword"
	return axios.post(url,{email}, header())
}
export const forgotPasswordCheckToken = (token) => {
	let url = baseURL + "api/forgotpassword"
	return axios.get(url,{params:{token}})
}
export const forgotPasswordNew = (password,token) => {
	let url = baseURL + `api/forgotpassword/${token}`
	return axios.post(url,{password})
}
// *****************FRIEND API CALLS ******************
export const addFriend = (data) => {
	let url = baseURL + "api/friends"
	return axios.post(url,data,header())
}

export const getFriendRequests =() => {
	let url = baseURL + "api/friendRequests"
	return axios.get(url,header())
}

export const acceptFriendRequest = (data) => {
	let url = baseURL + "api/friendRequests"
	return axios.put(url,data,header())
}

export const getFriends = () => {
	let url = baseURL + "api/friends"
	return axios.get(url,header())
}

// ******** CATEGORY API CALLS  ***************
export const fetchCategoryList = () =>{
	let url = baseURL + "api/category"
	return axios.get(url)
}

// ******** POST API CALLS ***************
export const fetchPostList = (params={}) =>{
	let url = baseURL + "api/post"
	return axios.get(url,{params})
}

export const fetchOnePost = (postId) =>{
	let url = baseURL + `api/post/${postId}`
	return axios.get(url,header())
}

export const updatePost = (data,postId) =>{
	let url = baseURL + `api/post/${postId}`
	return axios.put(url,data,header())
}

export const writePost = (data) =>{
	let url = baseURL + "api/post"
	return axios.post(url,data,header())
}

export const deletePost = (postId) =>{
	let url = baseURL + `api/post/${postId}`
	return axios.delete(url,header())
}

export const searchByTag = (searchTerm) => {
	let url = baseURL + `api/tags/${searchTerm}`
	return axios.get(url,header())
} 

// ****** COMMENT API CALLS ************
export const fetchPostComments = (postId) => {
	let url = baseURL + `api/comments/${postId}`
	return axios.get(url)
}

export const createPostComment = (data,postId) => {
	let url = baseURL + `api/comments/${postId}`
	return axios.post(url,data,header())
}

export const updatePostComment = (data,commentId) => {
	let url = baseURL + `api/comments/${commentId}`
	return axios.put(url,data,header())
}

export const deletePostComment = (commentId) => {
	let url = baseURL + `api/comments/${commentId}`
	return axios.delete(url,header())
}

// ********** UNIVERSE API CALLS ****************

export const createUniverse = (data) => {
	let url = baseURL + "api/universe"
	return axios.post(url,data,header())
}

export const getUniverses = () => {
	let url = baseURL + "api/universe"
	return axios.get(url,header())
}

export const getUniverseById = (id) => {
	let url = baseURL + `api/universe/${id}`
	return axios.get(url,header())
}

//  ************** MESSAGE AND CHAT API CALLS ***********

export const sendMessageFromPost = (message) => {
	let url = baseURL + 'api/messageFromPost'
	return axios.post(url, message, header())
}

export const getChats = () => {
	let url = baseURL + `api/chat`
	return axios.get(url,header())
}

//
export const getPrivacyPolicy = async () => {
	let url = baseURL + 'download'
	const data = await axios.get(url)
	console.log(data)
	FileDownload(data.data,'Freespot-PrivacyPolicy.docx')
}