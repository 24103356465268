import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types';
import moment from "moment"
import "./PostBlock.css"
import PostPlaceholder from "../../styles/freespot_userpost_default.png"
import ProfilePlaceholder from "../../styles/freespot_userprofile_default.png"

class PostBlock extends Component {
	static propTypes = {
		/*Block Container Height*/
		height: PropTypes.number,
		/*Block Container Width*/
		width: PropTypes.number,
		/*Post object to display, WHEN YOU HAVE TIME MAKE IT A SHAPE*/
		post: PropTypes.object.isRequired,
	};

	static defaultProps = {
	};

	render(){
		return(
			<NavLink className="Post-Block-Card-Container" to={`/post/${this.props.post.id}`}>
				<div 
					style={{
						backgroundImage: `url(${this.props.post.image || PostPlaceholder})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover"
					}}
					className="Post-Block-Card has-text-white"
				>			
					<div className="Post-Info">		
							
						<h1 className="bold is-size-4">{this.props.post.isRequest && "REQUEST:"}{this.props.post.title}</h1>
						{this.props.post.isRequest && <p>{this.props.post.description}</p>}
						<h1 className="">{this.props.post.user.username}</h1>
					</div>
				</div>
			</NavLink>
		)
	}
}

export default PostBlock