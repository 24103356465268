import React, {Component} from 'react';
import {connect} from "react-redux"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Notification extends Component {
	componentDidUpdate(prevProps) {
		if(this.props.timestamp > prevProps.timestamp){
			toast(`${this.props.title}: \n ${this.props.message}`,{
				level: this.props.level,
				autoDismiss: this.props.autoDismiss
			})
		}
	}

	render(){
		return (
			<ToastContainer draggable={80}/>
		)
	}

}

const mapStateToProps = (state) => {
	const { title, message, level, autoDismiss, timestamp } = state.Notif;
	return {
		title,
		message,
		level,
		autoDismiss,
		timestamp,
	};
}
export default connect(mapStateToProps,{})(Notification)

export const notify = (options) => {
	// console.log('%c options ', 'background: black; color: lime', options);
	return (dispatch) => {
		dispatch({
			type: 'NOTIFY',
			payload: {
				...options,
				timestamp: new Date().getTime(),
			},
		});
	};
};