import React, { Component } from 'react';
import Categories from "./Categories"
import Feed from "./Feed"

class Home extends Component {
	render(){
		return(
			<div>
				<Categories />
				<Feed />
			</div>
		)
	}
}

export default Home