const initialState = {
	registrationFetching: false,
	registrationFailed: false,
	registrationSuccess: false,
	loginFailed: false,
	loggedIn: false,
	forgotPasswordFetching: false,
	forgotPasswordFailed: false,
	forgotPasswordSuccess: false,
	passwordChangeSuccess: false,
	passwordChangeFail: false,
	username: "",
}

export default function (state = initialState, action){
	switch(action.type){//AUTH REDUCERS 
		case 'REGISTRATION_START':
			return {
				...state,
				registrationFetching: true,
			}
		case 'REGISTRATION_COMPLETE':
			return {
				...state,
				registrationFetching: false,
				registrationSuccess: true,
				registrationFailed: false,
				message: action.payload
			}
		case 'REGISTRATION_FAILED':
			return {
				...state,
				registrationFetching: false,
				registrationFailed: true,
				message: action.payload
			}
		case 'LOGIN_START':
			return {
				...state,
				loginFetching: true
			}
		case 'LOGIN_SUCCESS':
			return {
				...state,
				loginFetching: false,
				loginFailed: false,
				loggedIn: true,
				username:action.payload
			}
		case 'LOGIN_FAILED':
			return {
				...state,
				loginFetching: false,
				loginFailed: true,
				loginMessage: action.payload
			}
		case "FORGOTPASSWORD_START":
			return {
				...state,
				forgotPasswordFetching: true,
			}
		case "FORGOTPASSWORD_COMPLETE":
			return {
				...state,
				forgotPasswordFetching: false,
				forgotPasswordFailed: false,
				forgotPasswordSuccess: true,
			}
		case "FORGOTPASSWORD_FAIlED":
			return {
				...state,
				forgotPasswordFetching: false,
				forgotPasswordFailed: true,
				forgotPasswordSuccess: false,
			}
		case "PASSWORD_CHANGE_SUCCESS":
			return {
				...state,
				passwordChangeSuccess: true
			}
		case "PASSWORD_CHANGE_FAILED":
			return {
				...state,
				passwordChangeFail: true
			}
		case 'LOGOUT':
			return initialState
		default:
			return state;
	}
}