import {getUserProfile, addFriend} from "../../utils/api"

export const getProfile = (username) => async (dispatch) => {
	try{
		const {data} = await getUserProfile(username)
		if(data.success){
			console.log(data.profile)
			dispatch({
				type:'FETCH_PROFILE',
				payload: data.profile
			})
		}else{
			dispatch({
				type:'FETCH_PROFILE_FAILED',
			})
		}
	}catch(error){
		dispatch({
			type:'FETCH_PROFILE_FAILED',
		})
	}
}

export const sendFriendRequest = (userId) => async (dispatch) => {
	console.log(userId)
	try{
		const {data} = await addFriend({userId})
		if(data.success){
			dispatch({
				type: "FRIEND_REQUEST_SENT"
			})
		}else{
			dispatch({
				type: "FRIEND_REQUEST_FAILED"
			})
		}
	}catch(error){
		dispatch({
				type: "FRIEND_REQUEST_FAILED"
		})
	}
}