const initialState = {
	chats:[],
	messages: [],
	currentChatId: "",
	socket: null,

}

export default function (state = initialState, action){
	switch(action.type){
		case 'SOCKET_CREATED':
			return {
				...state,
				socket: action.payload
			}
		case 'JOIN_CHAT':
			return {
				...state,
				messages: action.payload
			};
		case 'CHAT_UPDATED':
			return {
				...state,
				messages: state.messages.concat(action.payload)
			};
		case "CHAT_CLEARED":
			return {
				...state,
				messages:[],
				socket: null
			}
		case 'MESSAGE_SENT':
			return {
				...state,
			};
		case 'FETCH_CHAT_SUCCESS':
			return {
				...state,
				chats: action.payload,
			}
		default:
			return state;
	}
}