import React, { Component } from 'react';
import { connect } from 'react-redux'
import LogRocket from 'logrocket';
import './App.css';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Home from "./components/Home"
import LandingPage from "./components/LandingPage"
import Post from "./components/Post"
import Profile from "./components/Profile"
import Header from "./components/Header"
import Footer from "./components/Header/Footer"
import Navigation from "./components/Header/Navigation"
import Search from "./components/Search"
import Inbox from "./components/Inbox"
import Universe from "./components/Universe"
import HomePage from "./components/HomePage"
import Notification from "./ui-components/Notification"
import ResetPassword from "./components/ResetPassword"
import {tempRefreshUser} from "./store/actions/HomeActions"

class App extends Component {
  componentDidMount(){
    LogRocket.init('ekalr7/freespotco')
    if(localStorage.getItem("token")){
      this.props.tempRefreshUser(localStorage.getItem("username"))
    }
  }
  render() {
    return (
      <div className="App">
        <Router>
          <span>
            <Header />
            <div className={this.props.loggedIn?"App-body-logged-in":"App-body"}>
              {this.props.loggedIn?<Navigation />:""}
              
                {
                  this.props.loggedIn?
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route path="/post" component={Post} />
                      <Route exact path="/profile/:username?" component={Profile} />
                      <Route exact path="/search/" component={Search} />
                      <Route path="/inbox/" component={Inbox} />
                      <Route path="/universe/" component={Universe} />
                      <Redirect to="/" />
                    </Switch>
                  :
                    <Switch>
                      <Route exact path="/register" component={LandingPage} />
                      <Route exact path="/reset/:token" component={ResetPassword} />
                      <Route exact path="/" component={HomePage} />
                      <Redirect to="/" />
                    </Switch>
                }
            </div>
            <Footer/>
          </span>
        </Router>
        <Notification />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    loggedIn: state.Auth.loggedIn
  }
}


export default connect(mapStateToProps,{tempRefreshUser})(App);