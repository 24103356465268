import React, { Component } from 'react';
import {connect} from "react-redux"
import {enterChat, clearMessages,} from "../../store/actions/MessageActions"
import fssendicon from "../../styles/fssendicon.png"

class Messenger extends Component {
	state = {
		content: ""
	}

	componentDidMount(){
		this.props.enterChat(this.props.match.params.chatId)
	};
	componentWillUnmount(){
		this.props.socket.removeListener("message")
		this.props.clearMessages()
	}

	handleSendMessage = () => {
		if(this.props.socket){
			console.log("Message Send Begin")
			const {content} = this.state
			if(content){
				this.props.socket.emit(
					"sendMessage",
					{
					chatId: this.props.match.params.chatId,
					content
					}
				)
				this.setState({
					content: ""
				})
			}
		}else{
			//Not Connected to socket!!
		}
	};

	handleChange = (e) =>{
		this.setState({
			[e.target.name] : e.target.value
		})
	};

	render(){
		console.log(this.props)
		return(
			<div>
				<div className="Message-Container">
					{this.props.messages.map(message=> 
						<div className={
							message.user.username===this.props.username?
							"Message-This-User": "Message-Other-User"
						}>
							<img className="Message-Profile-Image" src={message.user.profileImage} />
							<div className="Message-Content-Container">
								<div className="Message-Content">
									<p className="Message-Item-Text">{message.content}</p>
									{message.post && 
										<div>
											{message.post.title}
											<img className="Message-Item-Image" src={message.post.image}/>
									</div>}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="Input-With-Button">
					<input className="Message-Input-Field" name='content' value={this.state.content} onChange={this.handleChange} class="input is-large" placeholder="Type Something..." />
					<img className="Message-Submit-Button" onClick={this.handleSendMessage} src={fssendicon}/>
				</div>
			</div>
			)
		}
}

const mapStateToProps = (state) => {
	console.log(state)
	return{
		messages: state.Messenger.messages,
		username: state.Auth.username,
		socket: state.Messenger.socket,
	}
}

export default connect(mapStateToProps,{enterChat, clearMessages})(Messenger)