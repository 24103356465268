import {fetchOnePost, writePost,fetchCategoryList, updatePost, deletePost, fetchPostComments, createPostComment,getUniverses,sendMessageFromPost,/*updatePostComment*/} from "../../utils/api"

export const getPostDetails = (postId) => async (dispatch) => {
	try{
		const {data} = await fetchOnePost(postId)
		if(data.success){
			const {comments, ...post} = data.post
			dispatch({
				type: "FETCH_ONE_POST",
				post,
				comments 
			})
		}else{
			dispatch({
				type: "NOTIFY",
				payload: "Error Occurred"
			})
		}
	}catch(error){
		console.log(error)
	}
}

export const createPost = (newPost) => async (dispatch) => {
	try{
		dispatch({type:"CREATE_POST_START"})
		const {data} = await writePost(newPost)
		if(data.success){
			dispatch({
				type:"CREATE_POST_SUCCESS",
				payload:data.post
			})
		}else{
			dispatch({
				type: "CREATE_POST_FAILED",
				payload: data.message
			})
		}
	}catch(error){
		dispatch({
			type: "CREATE_POST_FAILED",
			payload: "Unexpected Error Creating Post"
		})
	}
}
export const resetCreateForm = () => (dispatch) => {
	dispatch({
		type:"RESET_CREATE_POST_FORM"
	})
}
export const getCategoryList = () => async (dispatch) => {
	try{
		const {data} = await fetchCategoryList()
		if(data.success){
			dispatch({
				type: "FETCH_CATEGORY_LIST",
				payload: data.categoryList
			})
		}else{
			dispatch({
				type: "FETCH_CATEGORY_LIST_FAILED"
			})
		}
	}catch{
		dispatch({type:"FETCH_CATEGORY_LIST_FAILED"})
	}
}

export const getUniverseList = () => async (dispatch) => {
	try{
		const {data} = await getUniverses()
		if(data.success){
			dispatch({
				type: "CREATE_POST__FETCH_UNIVERSE_LIST",
				payload: data.universes
			})
		}else{
			dispatch({
				type: "CREATE_POST__FETCH_UNIVERSE_LIST_FAILED"
			})
		}
	}catch{
		dispatch({type:"CREATE_POST__FETCH_UNIVERSE_LIST_FAILED"})
	}
}

export const editPost = (newPost,postId) => async (dispatch) => {
	try{
		dispatch({type: "EDIT_POST_START"})
		const {data} = await updatePost(newPost, postId)
		if(data.success){
			dispatch({
				type:"EDIT_POST_SUCCESS",
				payload: data.post
			})
			dispatch(getPostDetails(postId))
		}else{
			dispatch({type: "EDIT_POST_FAILED"})
		}
	}catch(error){
		dispatch({type: "EDIT_POST_FAILED"})
	}
}

export const removePost = () => async (dispatch) => {
	try{
		dispatch({type: "DELETE_POST_START"})
		const {data} = await deletePost()
		if(data.success){
			dispatch({
				type: "DELETE_POST_SUCCESS"
			})
		}else{
			dispatch({
				type: "DELETE_POST_FAILED"
			})
		}
	}catch(error){
		dispatch({
				type: "DELETE_POST_FAILED"
		})
	}
}

export const getComments= (postId) => async (dispatch) => {
	try{
		dispatch({type: "FETCHING_COMMENTS"})
		const {data} = await fetchPostComments(postId)
		if(data.success){
			dispatch({
				type: "FETCH_COMMENTS_SUCCESS",
				payload: data.comments
			})
		}else{
			dispatch({
				type: "FETCH_COMMENTS_FAILED"
			})
		}
	}catch(error){
		dispatch({
				type: "FETCH_COMMENTS_FAILED"
		})
	}
}

export const addPostComment = (newComment,postId) => async (dispatch) => {
	try{
		const {data} = await createPostComment(newComment,postId)
		if(data.success){
			dispatch({
				type:"ADD_COMMENT_SUCCESS",
				payload:data.message
			})
			dispatch(getComments(postId))
		}else{
			dispatch({
				type:"ADD_COMMENT_FAILED",
			})
		}
	}catch(error){
		dispatch({
				type:"ADD_COMMENT_FAILED"
			})
	}
}

export const sendMessageWithPost = (postId, successRedirect) => async (dispatch) => {
	try {
		const message = {
			content: "I would like to request this item",
			postId
		}
		const {data} = await sendMessageFromPost(message)
		console.log(data)
		if(data.success){
			successRedirect(data.chatId)
		}else{

		}
	}
	catch(error){
		console.log(error)
	}
}