import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PostView from './PostView'
import CreatePost from './CreatePost'

class Post extends Component {
	render(){
	
	return(
			<Switch>
				<Route exact path="/post/create-post" component={CreatePost} />
				<Route exact path="/post/edit-post/:id" component={CreatePost} />
				<Route exact path="/post/:id" component={PostView} />
			</Switch>
		)
	}
}

export default Post