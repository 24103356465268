import React, {Component} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {getUniverseDetail, changeUniverseDisplay} from "../../store/actions/UniverseActions"
import PostBlock from "../../ui-components/PostBlock"

const UniverseFriendCube = ({user}) => {
	console.log(user)
	return(
		<Link to={`/profile/${user.username}`}>
			<div
				style={{
						backgroundImage: `url(${user.profileImage})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover"
					}} 
				className="UniverseFriendCube"
			>
				<h1>{user.username}</h1>
			</div>
		</Link>
	)
}
class UniverseView extends Component {
	componentDidMount(){
		this.props.getUniverseDetail(this.props.selectedUniverse)
	}
	componentDidUpdate(prevProps){
		if(prevProps.selectedUniverse !== this.props.selectedUniverse){
			this.props.getUniverseDetail(this.props.selectedUniverse)
		}
	}

	render(){
		console.log(this.props)
		return(	
			<div>
				<div className="Universe-Navigation">
					<h1 onClick={()=>this.props.changeUniverseDisplay("Items")}>Items</h1>
					<h1 onClick={()=>this.props.changeUniverseDisplay("Friends")}>Friends</h1>
				</div>
				<hr className="Universe-Divider"/>
				{
					!this.props.universeDetail?
					<div> </div>
					:
					<div className="Universe-Body">
						{this.props.universeView === "Items"?
							this.props.posts.map(post => <PostBlock post={post} />)
							:
							this.props.users.map(user => <UniverseFriendCube user={user}/>)
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		universeDetail : state.Universe.universeDetail,
		universeView: state.Universe.universeView,
		users: state.Universe.users,
		posts: state.Universe.posts,
		fetchingUnivsereFail: state.Universe.fetchingUnivsereFail,
		fetchingUnivsereDetail: state.Universe.fetchingUnivsereDetail,
	}
}
export default connect(mapStateToProps,{getUniverseDetail, changeUniverseDisplay})(UniverseView)