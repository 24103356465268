const initialState = {
	universes: [],
	friends:[],
	fetchUniverseSuccess: false,
	fetchUniverseFailed: false,
	fetchingUnivsere:false,
	universeCreateSuccess: false,
	selectedUniverse: false,
	fetchingUnivsereDetail: false,
	fetchingUnivsereFail: false,
	universeDetail: false,
	users: [],
	posts: [],
	universeView: "Items"
}

export default function (state = initialState, action){
	switch(action.type){
		case 'UNIVERSE_FETCHING':
			return {
				...initialState,
				fetchingUnivsere: true
			};
		case 'UNIVERSE_FETCH_SUCCESS':
			return {
				...state,
				fetchUniverseSuccess: true,
				fetchingUnivsere:false,
				universes: action.payload
			};
		case 'UNIVERSE_FETCH_FAILED':
			return {
				...state,
				fetchUniverseFailed: true,
				fetchingUnivsere:false,
			};
		case 'UNIVERSE_CREATE_SUCCESS':
			return {
				...state,
				universeCreateSuccess: true,
			}
		case 'UNIVERSE_CREATE_CLEAR':
			return {
				...state,
				universeCreateSuccess: false,
			}
		case "UNIVERSE_FRIENDS_CHOICES":
			return {
				...state,
				friends: action.payload
			}
		case "UNIVERSE_DETAIL_FETCH":
			return{
				...state,
				fetchingUnivsereDetail: true
			}
		case "UNIVERSE_DETAIL_FETCH_FAIL":
			return{
				...state,
				fetchingUnivsereDetail: false,
				fetchingUnivsereFail: true,
			}
		case "UNIVERSE_DETAIL":
			return{
				...state,
				fetchingUnivsereDetail: false,
				universeDetail: action.universe,
				users: action.users,
				posts: action.posts,
			}
		case "UNIVERSE_DETAIL_RESET":
			return{
				...state,
				fetchingUnivsereDetail:false,
				universeDetail: false,
				users: [],
				fetchingUnivsereFail: false,
			}
		case 'SELECT_UNIVERSE':
			return {
				...state,
				selectedUniverse: action.payload,
			}
		case 'UNIVERSE_DISPLAY':
			return {
				...state,
				universeView: action.payload,
			}
		default:
			return state;
	}
}



