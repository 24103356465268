import React, { Component } from 'react';
import {connect} from "react-redux"
import "../../styles/LoginForm.css"
import {loginModalToggle,registerModalToggle,login,register,forgotPasswordSubmit} from '../../store/actions/HomeActions'
import Logo from "../../styles/freespotfavicon_lg.png"

const Register = (props) => {
	return(
		props.registrationSuccess?

			
			<div className="accountsuccessmessage">
				Success!
				<div className="accountsuccesstext">
				You did it! Welcome to FreeSpot! Go back to the Log In screen to access your account!
				<div className="control"> <button className="newuser-login-button" onClick={()=>props.toggleModal(true)}>Log In</button></div>
			</div>
			</div>
		
		
			
			:
			
			<div className="Register-Form form-containers"> 

			<div><img className="Web-Signin-Icon is-hidden-mobile" src={Logo} /></div>
			<div className="lostpasswordhead">Create An Account!</div>

				<form onSubmit={props.handleSubmit}>
					<label className="label">Profile Picture</label>
					<div className="control">
						<input onChange={props.handleFile} className="input" type="file" name="profileImage"/>
					</div>
					<br/>
					<div className="control">
						<input placeholder="Username" onChange={props.handleChange} className="input" name="username"/>
					</div>
					<br/>
					<div className="control">
						<input placeholder="Email" onChange={props.handleChange} className="input" name="email"/>
					</div>
					<br/>
					<div className="control">
						<input placeholder="Password-Use Caps, Numbers & Symbols*" onChange={props.handleChange} className="input" type="password" name="password"/>
					</div>
					<br/>
					<div className="control">
						<input placeholder="Bio" onChange={props.handleChange} className="input" type="textarea"name="bio"/>
					</div>
					<br/>
					<div className="control">
						<input onChange={props.handleChange} className="register-button" type="submit" value="Register"/>
					</div>
					<br/>
				</form>
				<h1 className="has-text-red">{props.registrationFailed?props.registerMessage:""}</h1>
			</div>
			
	)
}

const Login = (props) => {
	return(
		
		<div className="Login-Form has-text-black form-containers">
		<div><img className="Web-Signin-Icon is-hidden-mobile" src={Logo} /></div>
		<div className="lostpasswordhead">Welcome Back!</div>
			<form onSubmit={props.handleSubmit} className="control">
				<div className="control">
					<input placeholder="Email" className="input" name="logEmail" onChange={props.handleChange}/>
				</div>
				<br/>
				<div className="control">
					<input placeholder="Password" className="input" name="logPassword" type="password" onChange={props.handleChange}/>
				</div>
				<br/>
				<div className="control">
					<input className="login-button" type="submit" value="Log In"/>
				</div>
				<br/>
			</form>
			<h1 className="has-text-red">{props.loginFailed?props.loginMessage:""}</h1>
		</div>
		
	)
}

class LoginForm extends Component {
	constructor(){
		super()

		this.state = {
			...initalState
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleFile = this.handleFile.bind(this)
		this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this)
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
		this.handleForgotPassword = this.handleForgotPassword.bind(this)
		this.toggleForgotPassword = this.toggleForgotPassword.bind(this)
	}

	handleChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}
	handleFile(e){
		this.setState({
			[e.target.name]: e.target.files[0] 
		});
	};
	handleRegisterSubmit(e){
		e.preventDefault()
		let data = new FormData()
		data.append('profileImage',this.state.profileImage)
		data.append('username',this.state.username)
		data.append('email',this.state.email)
		data.append('password',this.state.password)
		data.append('bio',this.state.bio)
		
		this.props.register(data)
	}
	handleLoginSubmit(e){
		e.preventDefault()
		const data = {
			email: this.state.logEmail,
			password: this.state.logPassword
		}
		this.props.login(data)
	}
	handleForgotPassword(){
		this.props.forgotPasswordSubmit(this.state.forgotEmail)
	}
	toggleForgotPassword(){
		this.setState({
			forgotPassword: !this.state.forgotPassword
		})
	}
	render(){
		console.log(this.props)
		return(
			<div>
				{
				this.props.loginOpen?
					<div>
						{
						!this.state.forgotPassword?
						<Login
							toggleModal={this.props.loginModalToggle}
							handleChange={this.handleChange}
							handleSubmit={this.handleLoginSubmit}
							loggedIn={this.props.loggedIn}
							loginFailed={this.props.loginFailed}
							loginMessage={this.props.loginMessage}
						/>
						:
						<div>
							{!this.props.forgotPasswordSuccess?
								<div className="form-containers">
								<div><img className="Web-Signin-Icon is-hidden-mobile" src={Logo} /></div>
									<div className="lostpasswordhead">Lost Your Password?</div>
									<input placeholder="Enter Email" className="input" name="forgotEmail" type="email" onChange={this.handleChange} />
									<button className="submitlostpass-button" onClick={this.handleForgotPassword}>Submit </button>
								</div>
								: <div>
								<div className="lostpasswordhead" style={{marginTop:'10rem'}} >Almost There!</div>
								<div className="lostexp">We've sent you an email to help you reset your password. Remember to check your spam inbox if you can't find it.</div></div>}
						</div>
						
						}
						<div className="has-text-black">Don't have an account?<button className="orsignupbutton" onClick={()=>this.props.loginModalToggle(false)}>Sign Up Here</button></div>
						{!this.state.forgotPassword? <div className="forgotpassword" onClick={this.toggleForgotPassword}>Forgot your Password?</div> : <div className="forgotpassword" onClick={this.toggleForgotPassword}>Log In Here!</div>}
					</div>
					:
					<div>
						<Register
							toggleModal={this.props.loginModalToggle}
							handleChange={this.handleChange}
							handleFile={this.handleFile}
							handleSubmit={this.handleRegisterSubmit}
							registrationSuccess={this.props.registrationSuccess}
							registrationFailed={this.props.registrationFailed}
							registerMessage={this.props.registerMessage}

						/>
						<div className="has-text-black">Already have an account?<button className="orloginbutton" onClick={()=>this.props.loginModalToggle(true)}>Log In</button></div>

					</div>
			}

			</div>
		)
	}
}

const initalState = {
			username: "",
			email: "",
			bio: "",
			password: "",
			logEmail: "",
			logPassword:"",
			forgotPassword: false,
		}

const mapStateToProps = (state) => {
	return{
		loginOpen : state.Home.loginOpen,
		registerOpen : state.Home.registerOpen,
		registrationFailed: state.Auth.registrationFailed,
		registrationSuccess: state.Auth.registrationSuccess,
		loginFailed: state.Auth.loginFailed, 
		loggedIn: state.Auth.loggedIn,
		loginMessage: state.Auth.loginMessage,
		registerMessage: state.Auth.message,
		forgotPasswordFetching: state.Auth.forgotPasswordFetching,
		forgotPasswordFailed: state.Auth.forgotPasswordFailed,
		forgotPasswordSuccess: state.Auth.forgotPasswordSuccess,
	}
}

export default connect(mapStateToProps,{loginModalToggle,registerModalToggle,login,register,forgotPasswordSubmit})(LoginForm)