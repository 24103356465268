import io from 'socket.io-client'
import {getChats} from "../../utils/api"

export const enterChat = (chatId) => (dispatch) => {

	const socket = io( "https://freespot-server.herokuapp.com/",{
	  query: {
	    token: localStorage.getItem("token")
	  },
	  transports: ['websocket'],
	  upgrade: false,
	})
	dispatch({
		type:"SOCKET_CREATED",
		payload: socket,
	})

	socket.emit("room", chatId)
	socket.on("message", updatedChat => {
		console.log(updatedChat)
		dispatch({
			type:"CHAT_UPDATED",
			payload: updatedChat
		})
	})
}
export const clearMessages = () => (dispatch) => 
	dispatch({
		type: "CHAT_CLEARED"
	})

export const fetchChats = () => async (dispatch) => {
	try{
		const {data} = await getChats()
		if(data.success){
			dispatch({
				type: "FETCH_CHAT_SUCCESS",
				payload: data.chat,
			})
		}else{
			dispatch({
				type: "FETCH_CHAT_FAILED"
			})
		}
	}catch(error){
		dispatch({
				type: "FETCH_CHAT_FAILED"
		})
	}
}