import {fetchCategoryList, loginApi, registerApi, fetchPostList, forgotPasswordApi,forgotPasswordCheckToken,forgotPasswordNew} from "../../utils/api"

// CATEGORY ACTIONS
export const getCategoryList = () => async (dispatch) => {
	try{
		const {data} = await fetchCategoryList()
		if(data.success){
			dispatch({
				type: "FETCH_CATEGORY_LIST",
				payload: data.categoryList
			})
		}else{
			dispatch({
				type: "NOTIFY",
				payload: "Error Occurred"
			})
		}
	}catch(error){
		console.log(error)
	}
}

export const selectCategory = (category) => (dispatch) => {
	dispatch({
		type: "SELECT_CATEGORY",
		payload:category
	})
	dispatch(getPostForFeed({categoryId:category}))
}
// FEED ACTIONS
// PARAMS FOR FEED SHOULD BE CATEGORIES ONLY
export const getPostForFeed = (params) => async (dispatch) => {
	try{
		const {data} = await fetchPostList({...params, limit: 6})
		if(data.success){
			dispatch({
				type:"FETCH_POST_LIST",
				payload: data.posts
			})
		}else{
			dispatch({
				type: "NOTIFY",
				payload: "Error Occurred"
			})
		}
	}catch(error){
		console.log(error)
	}
}

export const getMorePosts = (params) => async (dispatch) => {
	try{
		console.log("START SCROLL LOAD")
		const {data} = await fetchPostList({...params, limit: 6})
		console.log(data)
		if(data.success){
			dispatch({
				type:"ADD_POST_LIST",
				payload: data.posts
			})
		}else{
			dispatch({
				type: "NOTIFY",
				payload: "Error Occurred"
			})
		}
	}catch(error){
		console.log(error)
		dispatch({
			type: "NOTIFY",
			payload: "Error Occurred"
		})
	}
}

// USER AUTH ACTIONS
export const loginModalToggle = (isOpen) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_LOGIN_MODAL',
		payload: isOpen
	})
}
export const registerModalToggle = (isOpen) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_REGISTER_MODAL',
		payload: isOpen
	})
}

export const login = (userCred) => async (dispatch) => {
	try{
		dispatch({type:"LOGIN_START"})
		const {data} = await loginApi(userCred)

		if(data.success){
			localStorage.setItem('token',data.token)
			localStorage.setItem('username',data.username)
			dispatch({type:"LOGIN_SUCCESS", payload:data.username})
			dispatch(loginModalToggle(false))
		}else{
			dispatch({type:"LOGIN_FAILED", payload:data.message})
		}

	}catch(error){
		dispatch({type:"LOGIN_FAILED", payload:"Login Failed"})
	}
}

export const register = (newUser) => async (dispatch) => {
	try{
		dispatch({type:"REGISTRATION_START"})
		const {data} = await registerApi(newUser)

		if(data.success){
			dispatch({type: "REGISTRATION_COMPLETE", payload:data.message})
		}else{
			dispatch({type:"REGISTRATION_FAILED", payload:data.message})
		}

	}catch(error){
		dispatch({type:"REGISTRATION_FAILED", payload:error.message})
	}
}

export const forgotPasswordSubmit = (email) => async (dispatch) => {
	try{
		console.log("start")
		dispatch({type:"FORGOTPASSWORD_START"})
		const {data} = await forgotPasswordApi(email)
		console.log(data)
		if(data.success){
			dispatch({type: "FORGOTPASSWORD_COMPLETE", payload:data.message})
		}else{
			dispatch({type:"FORGOTPASSWORD_FAIlED", payload:data.message})
		}
	}catch(error){
		console.log(error)
		dispatch({type:"FORGOTPASSWORD_FAIlED", payload:error.message})
	}
}
export const forgotPasswordTokenValidation = async(token) => {
	try{
		const {data} = await forgotPasswordCheckToken(token)
		if(data.success){
			return true
		}else{
			return false
		}
	}catch{
		return false
	}
}
export const forgotPasswordChange = (password,token) => async(dispatch) => {
	try{
		const {data} = await forgotPasswordNew(password,token)
		if(data.success){
			dispatch({type:"PASSWORD_CHANGE_SUCCESS"})
		}else{
			dispatch({type:"PASSWORD_CHANGE_FAILED"})
		}
	}catch(error){
		dispatch({type:"PASSWORD_CHANGE_FAILED"})
	}
}
export const logout = () => (dispatch) => {
	localStorage.removeItem('token')
	localStorage.removeItem('username')
	dispatch({type:"LOGOUT"})
}
export const tempRefreshUser = (username) => (dispatch) =>{
	dispatch({type:"LOGIN_SUCCESS", payload:username})
}