import React, { Component } from 'react';
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import { fetchChats } from "../../store/actions/MessageActions"

class Messages extends Component {
	componentDidMount(){
		this.props.fetchChats()
	};

	render(){
		return(
			<div>
				{
					this.props.chats.map((chat)=> 
						<Link to={`/inbox/messages/${chat.id}`}>
							<div>
								<h1 className="Chat-Username">
									{
										chat.users.length > 1?
										chat.users.filter((user)=> 
											user.username !== this.props.username)[0].username
										:
										this.props.username
									}
								</h1>
							</div>
						</Link>
					)
				}
			</div>
			)
		}
}

const mapStateToProps = (state) => {
	console.log(state)
	return{
		chats: state.Messenger.chats, 
		username: state.Auth.username,
	}
}

export default connect(mapStateToProps,{fetchChats})(Messages)
