import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import {forgotPasswordTokenValidation, forgotPasswordChange} from '../../store/actions/HomeActions'
import "./ResetPassword.css"

class ResetPassword extends Component {
	state = {
		valid: false,
		passwordMatch: ""
	}
	componentDidMount(){
		forgotPasswordTokenValidation(this.props.match.params.token).then(valid=>{
			this.setState({
				valid
			})
		})
	}
	handleChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}
	handleNewPasswordSubmit = () => {
		if(this.state.password1 !== this.state.password2){
			this.setState({
				passwordMatch:"Passwords do not match"
			})
		}else{
			this.setState({
				passwordMatch:""
			})
			this.props.forgotPasswordChange(this.state.password1,this.props.match.params.token)
		}
	}
	render(){
		console.log(this.props.passwordChangeSuccess)
		return(
			<div className="ResetPassword-Container">
			{this.state.valid? 
				<div>
					{this.props.passwordChangeSuccess?
						<div className="has-text-black"><h1>Password Changed Successfully <Link to="/">Head Home!</Link></h1></div>
						:
						<div>
							{this.state.passwordMatch}
							<br/>
							<label>New Password</label>
							<input className="input" type="password" name="password1" onChange={this.handleChange} />
							<label>Confirm New Password</label>
							<input className="input" type="password" name="password2" onChange={this.handleChange} />
							<button className="submitlostpass-button" onClick={this.handleNewPasswordSubmit}>Submit</button>
						</div>
					}
				</div>
				:
				<div>
					<h1>Sorry this token appears to be expired</h1>
				</div>
			}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return{
		passwordChangeSuccess : state.Auth.passwordChangeSuccess,
		passwordChangeFail : state.Auth.passwordChangeFail,
	}
}
export default connect(mapStateToProps,{forgotPasswordChange})(ResetPassword)