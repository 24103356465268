const initialState = {
	search: "",
	searchType: "Posts",
	fetchingSearch: false,
	results: [],
}

export default function (state = initialState, action){
	switch(action.type){
		case 'SEARCH_SETUP_SEARCH_TERM':
			return {
				...state,
				search: action.payload
			};
		case 'SEARCH_POSTS_GET_RESULTS_START':
			return {
				...state,
				fetchingSearch: true
			}
		case 'SEARCH_POSTS_GET_RESULTS':
			return {
				...state,
				fetchingSearch: false,
				results: action.payload
			}
		case 'SEARCH_POSTS_GET_RESULTS_FAILED':
			return {
				...state,
				fetchingSearch: false,
				errorMessages: "Failed to Fetch"
			}
		case 'SEARCH_USERS_GET_RESULTS_START':
			return {
				...state,
				fetchingSearch: true
			}
		case 'SEARCH_USERS_GET_RESULTS':
			return {
				...state,
				fetchingSearch: false,
				results: action.payload
			}
		case 'SEARCH_USERS_GET_RESULTS_FAILED':
			return {
				...state,
				fetchingSearch: false,
				errorMessages: "Failed to Fetch"
			}
		case "CHANGE_SEARCH_TYPE":
			return {
				...state,
				searchType: action.payload,
				results: []
			}
		default:
			return state;
	}
}