import React, { Component } from 'react';
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import Select from 'react-select'
import {createPost,getCategoryList,resetCreateForm, getUniverseList} from '../../store/actions/PostActions'
import "./CreatePost.css"

class CreatePost extends Component {

	constructor(){
		super()
		this.state = initalState
	}

	componentDidMount(){
		this.props.getCategoryList()
		this.props.getUniverseList()
	}
	componentWillUnmount(){
		this.props.resetCreateForm()
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	};
	handleCategoryChange = ({value}) => {
		this.setState({
			category:value
		})
	};
	handleUniverseChange = ({value}) => {
		this.setState({
			universe: value
		})
	};
	handleFile = (e) => {
		console.log(e.target.files[0])
		this.setState({
			[e.target.name]: e.target.files[0] 
		});
	};
	handleSubmit = () => {
		const {title,description,image,eventStart,eventEnd,category,universe,isRequest,} = this.state
		console.log(image)
		const tags = description.split(" ").filter(elem=> elem.startsWith("#")).map(tag=>({name:tag.toLowerCase().replace("#","")}))
		
		let newPost = new FormData()
		newPost.append('title',title)
		newPost.append('description',description)
		newPost.append('image',image)
		newPost.append('isRequest', isRequest)
		newPost.append('eventStart', eventStart)
		newPost.append('eventEnd',eventEnd)
		newPost.append('category', category)
		newPost.append('tags', tags)
		newPost.append('universe', universe)

		this.props.createPost(newPost)
	};

	resetCreateForm = () =>{
		this.setState(initalState)
		this.props.resetCreateForm()
	};

	changePostType = (bool) => {
		this.setState({
			isRequest: bool
		})
	};
	render(){
		console.log(this.props)
		return this.props.createPostSuccess ?
		(<div className="has-text-black">
			<h1>Post Created Successfully</h1>
			<h3>Create Another one?</h3>
			<div>
				<button className="button" onClick={this.resetCreateForm}>Yes</button>
				<Link to="/">
					<button className="button">No</button>
				</Link>
			</div>
		</div>
		)
		:
		(
		<div className="CreatePost-Form">
			<div className="Create-Post-Types">
				<h1 className={!this.state.isRequest && "Selected-post-Type"} onClick={()=>this.changePostType(false)}>Post an Item</h1>
				<h1 className={this.state.isRequest && "Selected-post-Type"} onClick={()=>this.changePostType(true)}>Request an Item</h1>
			</div>
			<div className="field">
				<label className="label">Title</label>
				<div className="control">
					<input className="input" placeholder="What is the item that you are posting?" name="title"  onChange={this.handleChange}/>
				</div>
			</div>
			<div className="field">
					<label className="label">Image</label>
					<div className="control">
						<input className="input" name="image" type="file"  onChange={this.handleFile}/>
					</div>
				</div>
			<div className="field">
				<label className="label">Description</label>
				<div className="control">
					<input className="textarea" placeholder="Tell us a bit more about the item..." name="description"  onChange={this.handleChange}/>
				</div>
			</div>
			
			<div >
				<label className="label">Category</label>
				<Select 
					onChange={this.handleCategoryChange}
					options={this.props.categories.map(category=> ({value:category.id,label:category.name}))} 
					/>
			</div>
			<div>
				<label className="label">Share with one of your Universes?</label>
				<Select 
					onChange={this.handleUniverseChange}
					options={this.props.universes.map(universe=> ({value:universe.id,label:universe.title}))} 
					/>
			</div>
			{
			this.state.category === (this.props.categories.find(i=>i.name==="Events")||{}).id && !this.state.isRequest?
			<span>
				<div className="field">
					<label className="label">Event Start</label>
					<div className="control">
						<input className="input" name="eventStart" type="date" onChange={this.handleChange} />
					</div>
				</div>
				<div className="field">
					<label className="label">Event End</label>
					<div className="control">
						<input className="input" name="eventEnd"  type="date" onChange={this.handleChange}/>
					</div>
				</div>
			</span>
			:
			null
			}
			<button onClick={this.handleSubmit} className="itembutton">Submit</button>
		</div>
		)
	}
}

const initalState = {
	title: "",
	description: "",
	image: "",
	eventStart: "",
	eventEnd:"",
	category:"",
	universe: "",
	isRequest: false,
}
const mapStateToProps = (state) => {
	return {
		categories: state.Post.categoryList,
		universes:state.Post.universeList || [],
		createPostSuccess: state.Post.createPostSuccess,
		createPostFailed: state.Post.createPostFailed,
		message: state.Post.message,

	}
}

export default connect(mapStateToProps,{createPost,getCategoryList,resetCreateForm, getUniverseList})(CreatePost)