import React, { Component } from 'react';
// import {connect} from "react-redux"
import {Link,withRouter} from "react-router-dom"

import "../../styles/Footer.css"
import Logo from "../../styles/freespotfavicon_lg.png"
import messages_fs from "../../styles/messages_fs.png"
import public_fs from "../../styles/public_fs.png"
import profile_fs from "../../styles/profile_fs.png"
import universe_fs from "../../styles/universe_fs.png"
import upload_fs from "../../styles/upload_fs.png"
import messages_fs_selected from "../../styles/messages_fs_selected.png"
import public_fs_selected from "../../styles/public_fs_selected.png"
import profile_fs_selected from "../../styles/profile_fs_selected.png"
import universe_fs_selected from "../../styles/universe_fs_selected.png"
import upload_fs_selected from "../../styles/upload_fs_selected.png"


class Footer extends Component {
	render(){
		console.log(this.props)
		return(
			<span>
				<div className="Navigation-Container is-hidden-mobile">
					<Link  to="/"><img src={Logo} /></Link>
					<div className="columns Navigation-Links">
						<div className="column">
							<Link to="/post/create-post">
								<h1>Add Post</h1>
							</Link>
						</div>
						<div className="column">
							<Link to="/universe">
								<h1>Universes</h1>
							</Link>
						</div>
						<div className="column">
							<Link to="/">
								<h1>Public</h1>
							</Link>
						</div>
						<div className="column">
							<Link to="/inbox">
								<h1>Inbox</h1>
							</Link>
						</div>
						
						<div className="column">
							<Link to="/profile">
								<h1>Profile</h1>
							</Link>
						</div>
					</div>
				</div>
				<div className="navbar is-fixed-bottom Navigation-Mobile is-hidden-tablet">
					<div className="Navigation-Icons">
						<div className="">
							<Link to="/post/create-post">
								<img src={
									this.props.location.pathname.includes("post")?
									upload_fs_selected : upload_fs
								} />
							</Link>
						</div>
						<div className="">
							<Link to="/universe">
								<img src={
									this.props.location.pathname.includes('universe')?
									universe_fs_selected : universe_fs
								} />
							</Link>
						</div>
						<div className="">
							<Link to="/">
								<img src={
									this.props.location.pathname ==='/' ?
									public_fs_selected : public_fs
								} />
							</Link>
						</div>
						<div className="">
							<Link to="/inbox">
								<img src={
									this.props.location.pathname.includes('inbox')?
									messages_fs_selected: messages_fs
								} />
							</Link>
						</div>
						
						<div className="">
							<Link to="/profile">
								<img src={
									this.props.location.pathname.includes('profile')?
									profile_fs_selected: profile_fs
								} />
							</Link>
						</div>
					</div>
				</div>
			</span>
		)
	}
}

export default withRouter(Footer)