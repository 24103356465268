import React, { Component } from 'react';
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {searchPosts,searchUsers,searchPostByTag, changeSearchType} from "../../store/actions/SearchActions"
import PostBlock from "../../ui-components/PostBlock"
import "../../styles/search.css"
import PostPlaceholder from "../../styles/freespot_userpost_default.png"
import ProfilePlaceholder from "../../styles/freespot_userprofile_default.png"

const SearchChoices = ({searchType,selectSearchType}) => {
	return(
	<div className="SearchChoices-Container">
		<div
			onClick={()=>selectSearchType("Posts")} 
			className={`SearchChoices-Choice ${searchType==="Posts"?"SearchChoices-selected":""}`}>
			Posts
		</div>
		<div
			onClick={()=>selectSearchType("Users")} 
			className={`SearchChoices-Choice ${searchType==="Users"?"SearchChoices-selected":""}`}>
			Users
		</div>
		<div
			onClick={()=>selectSearchType("Tags")} 
			className={`SearchChoices-Choice ${searchType==="Tags"?"SearchChoices-selected":""}`}>
			Tags
		</div>
	</div>
	)
}

const PostResults = ({results}) => {
	return(
		<div className="Search-Post-Results">
			{results.map(post=><PostBlock post={post} />)}
		</div>
	)
}

const UserResults = ({results}) => {
	return(
		<div>
			{results.map(user=>
			<Link to={`/profile/${user.username}`}>
				<div className="SearchResults-User-Container">
					<figure className="SearchResults-Avatar image is-64x64">
						<img src={user.profileImage || ProfilePlaceholder} />
					</figure>
					<div className="SearchResults-Username">
						{user.username}						
					</div>
				</div>
			</Link>)}
		</div>
	)
}

class Search extends Component {

	componentDidMount(){
		this.search()
	}
	componentDidUpdate(prevProps){
		if(prevProps.searchType !== this.props.searchType || prevProps.search !== this.props.search){
			this.search()
		}
	}
	selectSearchType = (type) => {
		this.props.changeSearchType(type)
	};

	search = () => {
		if(this.props.searchType === 'Posts'){
			console.log("POSTS")
			this.props.searchPosts(this.props.search)
		}else if(this.props.searchType === "Tags"){
			this.props.searchPostByTag(this.props.search)
		}
		else{
			console.log("USERS")
			this.props.searchUsers(this.props.search)
		}
	};

	render(){
		console.log(this.props)
		return(
			<div>
				<SearchChoices searchType={this.props.searchType} selectSearchType={this.selectSearchType} />
				<h1 className="has-text-white">Results for {this.props.search}</h1>
				{
					this.props.searchType === "Posts" || this.props.searchType === "Tags" ?
					<PostResults results={this.props.results}/>
					:
					<UserResults results={this.props.results} />
				}
			</div>
		)
	}
}

const mapStateToProps = (state) =>{
	return {
		searchType: state.Search.searchType,
		search: state.Search.search,
		results: state.Search.results
	}
}

export default connect(mapStateToProps,{searchPosts,searchUsers, changeSearchType, searchPostByTag})(Search)