import React, {Component} from "react"
import {Switch, Route} from "react-router-dom"
import UniverseCreate from "./UniverseCreate"
import AllUniverses from "./AllUniverses"
import "./Universe.css"

class Universe extends Component {
	render(){
		return(	
			<Switch>
				<Route exact path="/universe" component={AllUniverses} />
				<Route exact path="/universe/create" component={UniverseCreate} />
			</Switch>
		)
	}
}

export default Universe