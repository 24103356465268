import React, { Component } from 'react';
import {connect} from "react-redux"
import { Switch, Route, Link } from "react-router-dom"
import FriendRequests from "./FriendRequests"
import Messages from "./Messages"
import Messenger from "./Messenger"
import "./Inbox.css"

class Inbox extends Component {
	render(){
		return(
				<div className="Inbox-Container">
					<div className="Inbox-Banner is-hidden-mobile">
						<h1>INBOX</h1>
					</div>
					<div className="Inbox-Nav">
						<Link to="/inbox/"><h1>Friend Requests</h1></Link>
						<Link to="/inbox/messages"><h1>Messages</h1></Link>
					</div>
					<hr className="Inbox-Divider" />
					<Switch>
						<Route exact path="/inbox/"  component={FriendRequests} />
						<Route exact path="/inbox/messages/" component={Messages} />
						<Route exact path="/inbox/messages/:chatId" component={Messenger} />
					</Switch>
				</div>
			)
	}
}

const mapStateToProps = (state) => {
	return{
	}
}

export default connect(mapStateToProps,{})(Inbox)