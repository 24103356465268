const defaultState = {
	title: null,
	message: null,
	level: 'info',
	timestamp: 0,
};
export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'NOTIFY': {
			return {
				...defaultState,
				...action.payload,
			};
		}
		default: {
			return state;
		}
	}
}
