import React, { Component } from 'react';
import LoginForm from "../Header/LoginForm"
import "./HomePage.css"
import dude from "../../styles/dude.PNG"
import bgblock from "../../styles/bg-pattern.png"
import donateImage from "../../styles/bg-cta.jpg"
import demoScreen from "../../styles/demo-screen-1.jpg"
import appStoreBadge from "../../styles/app-store-badge.png"
import playStoreBadge from "../../styles/google-play-badge.png"
import twitterIcon from "../../styles/tw-icon.png"
import instagramIcon from "../../styles/ig-icon.png"
import facebookIcon from "../../styles/fb-icon.png"
import Logo from "../../styles/freespotfavicon_lg.png"
import {Link, withRouter} from 'react-router-dom';

class HomePage extends Component {
	state={
		email: '',
		firstName:'',
		lastName: '',
		birthday: '',
		isModalOpen: true,
	}
	handleChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	handleMailChimp = () =>{

	}
	closeModal = () => {
		this.setState({
			isModalOpen: false,
		})
	}
	render(){
		return(
			<div>
				<div  className="HomePage-header is-hidden-mobile">
					<div className="headerbgpattern">
						<div className="call-to-act columns">
							<div className="column">
								<h1 className="HomePageTitle">FreeSpot Co.</h1>
								<p className="HomePageTitleSupp">The app that connects you with free stuff!</p>
								<a href="/register"><button className="ogcta">Try Our Web Version Today</button></a>
							</div>
							<div className="column">
								<div className="HomePage-Phone">
									<img src={demoScreen}/>
								</div>
							</div>
						</div>
					</div>
				</div>
					


					<div  className="ourstory is-hidden-mobile">
						<div>
							<h1 className="ourstorycta">Start Finding Free Stuff and Reducing Waste Today!</h1>
							<p className="ourstorytext">Our purpose is to work to cut down on waste of all kinds. For example, 52 million tons of food are sent to a landfill. Meanwhile, 1 in 7 Americans are food insecure. Looking at food as just one example, misuse of resources extends far past the food industry. By creating a platform intended to share unused resources of all kinds, we hope to cut down the amount of people living in need as well as the amount of waste in the United States.</p>
						</div>
						<div className="columns downloadappbuttons">
								<div className="column downloadbutton">
									<a href="https://apps.apple.com/gb/app/freespot-co/id1477121194">
										<img src={appStoreBadge}/>
									</a>
								</div>

								<div className="column downloadbutton">
									<a href="https://play.google.com/store/apps/details?id=com.freespotnative">
								<img src={playStoreBadge}/></a>
								</div>
						</div>
					</div>





				<div>
					<section class="donate is-hidden-mobile">
					<div className="donateImage">
						<div className="helpus-section">
							<h6 className="helpus-headtext">Help Us Get Free!</h6>
							<p className="helpustext">Every contribution made towards FreeSpot goes toward building the framework of a community prioritizing the end of inequality. With your help, we are better able to create platforms that break through barriers limiting access for millions of Americans.</p>
							<a href="https://paypal.me/freespotco?locale.x=en_US" target="_blank"><button className="ogcta">donate</button></a>
						</div>
						
					</div>

						<div class="overlay"></div>
					</section>
				</div>



			
					<div  className="socialmedia is-hidden-mobile">
						<div>
							<h6 className="socialcta">See What We're Up To!</h6>
						</div>
							<div className="columns followusbuttons">
									<div className="column followbutton">
										<a href="https://twitter.com/FreeSpotco" target="_blank">
											<img src={twitterIcon}/>
										</a>
									</div>

									<div className="column followbutton">
										<a href="https://www.instagram.com/freespotco/" target="_blank">
									<img src={instagramIcon}/></a>
									</div>

									<div className="column followbutton">
										<a href="https://www.facebook.com/FreeSpotco/" target="_blank">
									<img src={facebookIcon}/></a>
									</div>
							</div>
					</div>
				<div/>
				
				{/*is-hidden-desktop meants this will only be visible on tablets and cellphones*/}
				 <div className="is-hidden-tablet">
				 	<div className="mobile-login-form">
				 		<LoginForm />
				 	</div>
				 	<div class={`modal ${this.state.isModalOpen? 'is-active' : ''}`}>
					  <div class="modal-background"></div>
					  <div class="modal-content mobile-modal has-background-white is-rounded">
					  <Link to="/"><img className="Mobile-Header-Icon is-hidden-tablet" src={Logo} /></Link>
					  	<h1 className="solemobilecta">Download FreeSpot Today!</h1>
					  		<a href="https://apps.apple.com/gb/app/freespot-co/id1477121194">
								<img className="Mobile-store-badge" src={appStoreBadge}/>
							</a>
					  		<a href="https://play.google.com/store/apps/details?id=com.freespotnative">
								<img className="Mobile-store-badge" src={playStoreBadge}/>
							</a>
						<h1 className="continuetowebtext" onClick={this.closeModal}>Continue to Mobile Version</h1>
					  </div>
					  <button class="modal-close is-large" aria-label="close" onClick={this.closeModal}></button>
					</div>
				 </div>
			</div>
		)
	}
}

export default HomePage