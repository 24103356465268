import {getFriendRequests, acceptFriendRequest} from "../../utils/api"

export const fetchFriendRequests = () => async (dispatch) =>{
	try{
		const {data} = await getFriendRequests()
		if(data.success){
			dispatch({
				type: "FETCH_FRIEND_REQUESTS",
				payload: data.friendRequests
			})
		}else{
			dispatch({
				type: "FETCH_FRIEND_REQUESTS_FAILED",
			})
		}
	}catch(error){
		dispatch({
			type: "FETCH_FRIEND_REQUESTS_FAILED",
		})
	}
}

export const acceptRequest = (userId) => async (dispatch) => {
	try{
		const {data} = await acceptFriendRequest({userId})
		if(data.success){
			dispatch({
				type:"FRIEND_REQUEST_ACCEPTED",
			})
			dispatch(fetchFriendRequests())
		}else{
			dispatch({
				type: "FRIEND_REQUEST_ACCEPTED_FAILED"
			})
		}
	}catch(error){
		dispatch({
			type: "FRIEND_REQUEST_ACCEPTED_FAILED"
		})
	}
}

export const changeInboxView = (value) => (dispatch) => {
	dispatch({
		type: 'CHANGE_INBOX_VIEW',
		payload: value
	})
}