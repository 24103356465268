const initialState = {
	categoryList: [],
	selectedCategory: "",
	postsList: [],
	universeList: [],
	loginOpen: false,
	registerOpen: false,
	lastPost: null,
}

export default function (state = initialState, action){
	switch(action.type){
		case 'FETCH_CATEGORY_LIST':
			return {
				...state,
				categoryList: action.payload
			};
		case 'SELECT_CATEGORY':
			return {
				...state,
				selectedCategory: action.payload,
				lastPost: null,
			};
		case 'TOGGLE_LOGIN_MODAL':
			return{
				...state,
				loginOpen: action.payload
			}
		case 'TOGGLE_REGISTER_MODAL':
			return{
				...state,
				registerOpen: action.payload
			}
		case "FETCH_POST_LIST":
			return{
				...state,
				postsList: action.payload,
				lastPost: action.payload.length? action.payload[action.payload.length-1].id : state.lastPost
			}
		case "ADD_POST_LIST":
			return {
				...state,
				postsList: state.postsList.concat(action.payload),
				lastPost: action.payload.length? action.payload[action.payload.length-1].id : state.lastPost
			}
		case "FETCH_UNIVERSE_LIST":
			return{
				...state,
				universeList: action.payload
			}
		default:
			return state;
	}
}