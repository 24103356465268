import{ combineReducers } from 'redux';
import Home from "./reducers/homeReducer"
import Auth from "./reducers/authReducer"
import Post from "./reducers/postReducer"
import Search from "./reducers/searchReducer"
import Profile from "./reducers/profileReducer"
import Inbox from "./reducers/inboxReducer"
import Messenger from "./reducers/messengerReducer"
import Universe from "./reducers/universeReducer"
import Notif from "./reducers/notificationReducer"

const appReducer = combineReducers({
	Home,
	Auth,
	Post,
	Search,
	Profile,
	Inbox,
	Messenger,
	Universe,
	Notif,
});

export default function (state, action){
	return appReducer(state, action)
}
