import React, { Component } from 'react';
import {connect} from "react-redux"
import {getProfile, sendFriendRequest} from '../../store/actions/ProfileActions'
import {logout} from '../../store/actions/HomeActions'
import PostBlock from "../../ui-components/PostBlock"
import "./Profile.css"
import ProfilePlaceholder from "../../styles/freespot_userprofile_default.png"

const Avatar = ({username,profileImage,userId, addFriend, loggedInUsername, logout}) => {
	return (
		<div className="Avatar">
			<img src={profileImage|| ProfilePlaceholder} alt="NONE" />
			
		</div>
	)
}

const UserInfo = (props) => {
	return(
		<div>
			<h4 className="has-text-weight-bold Profile-Username">{props.username}</h4>
			<div>{props.bio}</div>
		</div>
	)
}
const Loggy = ({username,profileImage,userId, addFriend, loggedInUsername, logout}) => {
	return(
		<div className="Profile-Friend-Options">
				{loggedInUsername === username?
					<button className="profilemainbutton" onClick={logout}>Logout</button>:
					<button 
					className="profilemainbutton"
					onClick={()=>addFriend(userId)}>Add Friend</button>
				}
			</div>

	)
}



class Profile extends Component {
	componentDidMount(){
		const username = this.props.match.params.username
		this.props.getProfile(username||"")
	}

	render(){
		console.log(this.props)
	return(
			<div className="">
				<hr/>
					<div className="Profile-Top-Section">
						<Avatar username={this.props.user.username} logout={this.props.logout} loggedInUsername={this.props.loggedInUsername} profileImage={this.props.user.profileImage} userId={this.props.user.id} addFriend={this.props.sendFriendRequest}/>
						<UserInfo username={this.props.user.username} bio={this.props.user.bio} />
						<Loggy username={this.props.user.username} logout={this.props.logout} loggedInUsername={this.props.loggedInUsername} profileImage={this.props.user.profileImage} userId={this.props.user.id} addFriend={this.props.sendFriendRequest}/>
					</div>

				<hr/>
				<div className="Profile-Posts">
					{(this.props.user.posts || []).map(post=><PostBlock post={post}/>)}
				</div>
			</div>
		)
	}
}





const mapStateToProps = (state) => {
	return {
		user : state.Profile.currentUser,
		loggedInUsername: state.Auth.username 
	}
}
export default connect(mapStateToProps, {getProfile, sendFriendRequest,logout})(Profile)