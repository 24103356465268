import React, { Component } from 'react';
import {connect} from "react-redux"
import {getCategoryList, selectCategory} from '../../store/actions/HomeActions'
import "../../styles/category.css"

const CategoryCard = ({category,handleCategorySelect,selectedCategory}) => {
	return(
		<div 
			className={`CategoryCard-card-body ${selectedCategory===category.id?"SelectedCategory":""}`} 
			onClick={()=>handleCategorySelect(category.id)}
		>
			<div
				style={{
					backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.0), rgba(0, 0, 0, 0.73)),url(${category.image})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}} 
				className="Category-Image-Container">
				<div className="CategoryCard-card-information has-text-centered has-text-white">
					<h1 className="is-size-4 CategoryCard-Name">{category.name}</h1>
					<h4 className="CategoryCard-Description is-hidden-mobile">{category.description}</h4>
				</div>
			</div>
		</div>
	)
}

class Categories extends Component {
	constructor(){
		super()
		this.handleCategorySelect = this.handleCategorySelect.bind(this)
	}
	componentDidMount(){
		this.props.getCategoryList()
	}

	handleCategorySelect(category){
		if(category === this.props.selectedCategory){
			category = ""
		}
		this.props.selectCategory(category)
	}

	render(){
		return(
			<div className="Categories-carousel">
				{this.props.categoryList.map(category=><CategoryCard category={category} handleCategorySelect={this.handleCategorySelect} selectedCategory={this.props.selectedCategory}/>)}
				
			</div>
		)
	}
}

const  mapStateToProps = (state) => {
	return {
		categoryList: state.Home.categoryList,
		selectedCategory: state.Home.selectedCategory
	}
}
export default connect(mapStateToProps, {getCategoryList,selectCategory})(Categories)